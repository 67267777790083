<template>
  <div class="recharge common-bg">
    <van-nav-bar title="藏品上传" left-arrow @click-left="onClickLeft" />
    <div class="content-box">
      <div class="pay-img">
        <div class="img1">
          <div class="jiahao">
            <van-uploader
              v-model="fileList"
              :before-read="beforeRead"
              :after-read="afterRead"
              multiple
              :max-count="1"
            />
          </div>
          <div class="jiatxt">
            <div><span>藏品照片</span></div>
          </div>
        </div>
      </div>
    </div>
    <div style="position: fixed; bottom: 10px; width: 89%; margin: 0 20px">
      <van-button type="warning" block @click="onSubmit"
        >立即上传</van-button
      >
    </div>
  </div>
</template>

<script>
import clipboard from '@/utils/clipboard'
import {  upload, addusershucang } from '@/utils/api.js'
export default {
  name: 'shucangup',
  data() {
    return {
      img: '',
      fileList: [],
    }
  },
  methods: {

    beforeRead(file) {
      if (
        file.type !== 'image/jpg' &&
        file.type !== 'image/jpeg' &&
        file.type !== 'image/png'
      ) {
        this.$toast('请上传jpg或png格式的图片')
        return false
      }
      return true
    },
    afterRead(file) {
      const formData = new FormData()
      formData.append('file', file.file)
      upload(formData).then((res) => {
        if (res.code === 200) {
          this.$toast('上传成功')
          this.img = res.data
        } else {
          this.$toast('上传失败')
        }
      })
    },
    onSubmit() {
      if (this.img === '') {
        this.$toast('请上传藏品照片')
        return
      }
      this.$toast.loading({
        duration: 0,
        message: '提交中...',
        forbidClick: true
      })
      const param = {
        img: this.img,
      }
      addusershucang(param).then((res) => {
        this.$toast.clear()
        if (res.code === 200) {
          this.$dialog
            .alert({
              title: '温馨提示',
              message: res.message,
              theme: 'round-button'
            })
            .then(() => {
              this.onClickLeft()
            })
        } else {
          this.$toast(res.message)
        }
      })
    },
    handleClipboard(text, event) {
      clipboard(text, event)
    },
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.qrcode-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-box {
  margin: 0 20px;
  padding: 20px 15px;
  background: hsla(0, 0%, 100%, 0.7);
  .van-cell {
    background: transparent !important;
    border-bottom: 1px solid;
    padding: 15px 0 2px 1px;
  }
  .content-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .set-li {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
    padding: 12px 0;
    .set-li-l {
      display: flex;
      align-items: center;
    }
    .set-li-r {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: space-between;
      margin-left: 10px;
    }
    .set-li-img img {
      width: 20px;
      height: 20px;
    }
  }
  .pay-img {
    width: 100%;
    display: flex;
    justify-content: center;
    .img1 {
      width: 150px;
      height: 150px;
      margin: 25px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;
      border: 1px dashed #ccc;

      .jiatxt {
        position: absolute;
        bottom: 5px;
        width: 100%;
        text-align: center;
        font-size: 16px;
        padding: 5px 0;
      }
    }
  }

  .pay-type {
    padding: 10px 0;
    .van-radio {
      margin-bottom: 10px;
    }
  }
}
</style>
